import { createListenerMiddleware } from '@reduxjs/toolkit'
import L from 'leaflet'
import 'leaflet.motion/dist/leaflet.motion'
import { colorIndex } from '../../lib/colors'

import { reached } from '../stats/statsSlice'

/** The listener listens to change in team position and moves the circle on the map */

const listenerMiddleware = createListenerMiddleware()

// Before map is being initialized.
let map = null;

L.Map.addInitHook(function() {
  map = this
});

listenerMiddleware.startListening({
  actionCreator: reached,
  effect: async (action, listenerApi) => {
    console.debug('Reached', action.payload.time, action.payload)

    const duration = 2000

    const state = (listenerApi.getState() as any)
    const game = state.game.game
    const teamLocations = (listenerApi.getOriginalState() as any).team_locations.teams
    const arrival = action.payload
    const team = game.teams[arrival.team_id]

    const getLocation = (location_paper_id: string) => {
      const locationPaper = game.location_papers[location_paper_id]
      if (!locationPaper)
        return
      const locationId = locationPaper.location_id
      const location = game.locations[locationId]
      if (!location.lat || !location.lng)
        return

      return location
    }

    const geo = (location) => [location.lat, location.lng]
    const prev = teamLocations[arrival.team_id]
    const prevLocation = getLocation(prev)
    const nextLocation = getLocation(arrival.location_paper_id)

    const icon = L.divIcon({
      html: `<span>${team.name}<span>`,
      iconSize: [15, 15],
      className: `marker team bg-${colorIndex(team.id)}`,
    })

    if (prevLocation && nextLocation && prevLocation.id !== nextLocation.id) {

      // @ts-ignore
      L.motion.polyline([geo(prevLocation), geo(nextLocation)],
        {
          color: "black",
          weight: 5,
          opacity: 0.008
        },
        {
          auto: true,
          duration: duration,
          // @ts-ignore
          easing: L.Motion.Ease.easeInQuad
        },
        {
          opacity: 0.5,
          autoPanOnFocus: true,
          riseOnHover: true,
          removeOnEnd: true,
          showMarker: false,
          icon: icon
        }).addTo(map)
    }
  }
})

export default listenerMiddleware;
