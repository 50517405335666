import { PriorityQueue, MinPriorityQueue } from '@datastructures-js/priority-queue';

interface Event {
  time: Date,
  callback: () => void
}

class Timer {
  rate: number;
  start: Date;
  running: boolean;
  currentTime: Date;
  timeout: ReturnType<typeof setTimeout>;
  end: Date;
  queue: PriorityQueue<Event>;
  events: Map<string, Event>;

  constructor() {
    this.queue = new MinPriorityQueue<Event>((event) => event.time.getTime());
    this.events = new Map()
    this.running = false
    this.rate = 128
  }

  getRate() {
    return this.rate
  }

  setRate(rate: number) {
    return this.rate = rate
  }

  pause() {
    this.running = false
    clearTimeout(this.timeout)
  }

  continue() {
    this.running = true
    this.schedule()
  }

  isRunning() {
    return this.running
  }

  run() {
    console.log('Started', this.start)
    this.running = true
    this.currentTime = this.start
    this.schedule()
  }

  reset() {
    this.running = false
    this.currentTime = this.start
    this.queue.clear()
    this.events.forEach(({ time, callback }, _) => this.queue.enqueue({ time, callback }))
    clearTimeout(this.timeout)
  }


  schedule() {
    if (this.queue.isEmpty()) return

    while (!this.queue.isEmpty() && this.queue.front().time.getTime() <= this.currentTime.getTime()) {
      const event = this.queue.pop()
      console.log('instant callback', event.time);
      event.callback()
    }

    const event = this.queue.front()
    const delay = Math.max(0, event.time.getTime() - this.currentTime.getTime()) / this.rate
    console.debug('Next event is scheduled in', delay / 1000, 's at ', event.time)

    this.timeout = setTimeout(() => {
      const event = this.queue.pop()
      this.schedule()
      this.currentTime = event.time
      event.callback()
    }, delay)
  }

  /** Schedule a callback after a delay in milliseconds. */
  setTimeout(event: { id: string }, callback: () => void, delay: number) {
    this.setTrigger(event, callback, new Date(this.start.getTime() + delay * 1000))
  }

  /** Schedule a callback at a specific time. Time can be a Date or a number (timestamp). */
  setTrigger({ id }, callback: () => void, time: Date) {
    if (!time)
      return
    if (this.events.has(id)) {
      return
    } else {
      this.events.set(id, { time, callback })
    }

    if (time.getTime() < this.currentTime.getTime()) {
      callback()
    } else {
      this.queue.enqueue({ time, callback })
      if (this.running && (this.queue.size() === 1 || time.getTime() < this.queue.front().time.getTime())) {
        clearTimeout(this.timeout)
        this.schedule()
      }
    }
  }

  toggle() {
    if (this.isRunning()) {
      this.pause()
    } else {
      this.continue()
    }
  }

  // setInterval(callback, interval [, firstTime]) {
  // }
  //
  //
  getStart(): Date {
    return this.start
  }
  setStart(start: Date) {
    this.start = start
  }
  getEnd(): Date {
    return this.end
  }
  setEnd(end: Date) {
    this.end = end
  }

  getCurrentTime() {
    return this.currentTime
  }

  getProgress() {
    if (!this.currentTime)
      return
    return (this.currentTime.getTime() - this.start.getTime()) / (this.end.getTime() - this.start.getTime())
  }

};

export default Timer;

