import { AuthenticationContext } from "../contexts/AuthenticationContext";
import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { useAppDispatch } from '../lib/hooks'

import { q } from "../lib/Query";
import Timer from "../lib/timer";
import { api, handleData, initialState } from "../lib/api";
import { Journal } from "../features/journal/Journal";
import { Arrival } from "../lib/interface";
import { reached } from "../features/stats/statsSlice";
import { Map } from "../features/map/Map";
import { GameContext } from "../contexts/GameContext";
import { TimerControls } from "../features/timer/TimerControls";
import { Dimmer, Loader } from "semantic-ui-react";
import { LocationPaperCount } from "../features/location_paper_count/LocationPaperCount";

function GamePanel() {
  const game = React.useContext(GameContext);
  const [arrivals, setArrivals] = React.useState(initialState([]));
  const [auth] = React.useContext(AuthenticationContext);
  const [timer] = React.useState(new Timer());
  const [locationPaper, setLocationPaper] = React.useState(null)
  const handleError = useErrorHandler();
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (game.didInvalidate) return;
    if (game.isFetching) return;
    if (!arrivals.didInvalidate) return;
    if (arrivals.isFetching) return;

    const start = new Date(Date.parse(game.data.start))
    const end = new Date(Date.parse(game.data.end))

    timer.setStart(start)
    timer.setEnd(end)
    timer.run()

    api(auth?.token, "stats")
      .from("arrivals")
      .select(
        q([
          "id:arrival_id",
          "team_id",
          "location_paper_id",
          "time",
          { "teams!inner": ["team_id", "game_id", "name"] },
        ]),
      )
      .eq("teams.game_id", game.data.game_id)
      .order('time')
      .then(handleData(handleError, setArrivals, (arrivals: Arrival[]) => {
        const prevArrivals = {}
        arrivals.forEach((arrival: Arrival) => {
          const { team_id } = arrival
          const prev = prevArrivals[team_id]
          if (prev) {
            prev.next = arrival
          }
          prevArrivals[team_id] = arrival
        })
        return arrivals
      }), handleError);
  }, [game, timer, auth, arrivals, handleError]);

  React.useEffect(() => {
    if (!game) return;
    if (arrivals.didInvalidate) return;
    if (arrivals.isFetching) return;


    arrivals.data.filter(({ time }) => time).forEach((arrival: Arrival) => {
      const time = new Date(Date.parse(arrival.time))
      timer.setTrigger(arrival, () => dispatch(reached(arrival)), time)
    })
  }, [game, arrivals, timer, dispatch]);

  return (
    <>
      <Dimmer active={game.isFetching}>
        <Loader />
      </Dimmer>
      <Journal />
      <LocationPaperCount locationPaper={locationPaper} setLocationPaper={setLocationPaper} />
      <Map setLocationPaper={setLocationPaper} />
      <TimerControls timer={timer} />
    </>
  );
}

export default GamePanel;

