import { Menu, Progress } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { useAppDispatch } from '../../lib/hooks'
import { cs } from "date-fns/locale";
import { format } from "date-fns";

import './TimerControls.css'
import Timer from '../../lib/timer';
import { stop, reset } from "./timerSlice";

export function TimerControls({ timer }: { timer: Timer }) {
  const dispatch = useAppDispatch()

  const [time, setTime] = useState(null)
  const [progress, setProgress] = useState(timer.getProgress())
  const [running, setRunning] = useState(true)
  const [rate, setRate] = useState(timer.getRate())

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer.isRunning()) {
        setTime(timer.getCurrentTime() || timer.getStart())
        setProgress(timer.getProgress())
      }
    }, 50)
    return () => clearInterval(interval)
  }, [timer])

  const handleStopClick = () => {
    timer.reset()
    setRunning(timer.isRunning())
    dispatch(reset())
  }

  const handlePlayPauseClick = () => {
    timer.toggle()
    setRunning(timer.isRunning())
    dispatch(stop())
  }

  const handleBackwardClick = () => {
    timer.setRate(timer.getRate() * 2)
    setRate(timer.getRate())
  }

  const handleForwardClick = () => {
    timer.setRate(timer.getRate() / 2)
    setRate(timer.getRate())
  }

  return <Menu attached="bottom">
    <Menu.Item icon={"stop"} onClick={handleStopClick} disabled={running} />
    <Menu.Item icon={running ? "pause" : "play"} onClick={handlePlayPauseClick}>
    </Menu.Item>
    <Menu.Item icon="backward" onClick={handleForwardClick}>
    </Menu.Item>
    <Menu.Item icon="forward" onClick={handleBackwardClick}>
    </Menu.Item>
    <Menu.Item className="progress">
      <span>{time && format(timer.getStart(), "HH:mm", { locale: cs, })}</span>
      <Progress attached="bottom" percent={100 * progress} />
      <span>{time && format(timer.getEnd(), "HH:mm", { locale: cs, })}</span>
    </Menu.Item>
    <Menu.Item className="rate" position="right">
      {rate}×
    </Menu.Item>
    <Menu.Item >
      {time && format(time, "HH:mm", { locale: cs, })}
    </Menu.Item>
  </Menu >
}
