import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../lib/store'
import { format } from "date-fns";
import { cs } from "date-fns/locale";

import { statsSlice } from '../stats/statsSlice'
import { timerSlice } from '../timer/timerSlice'
import type { Arrival } from '../../lib/interface'

interface JournalState {
  messages: string[]
}

const initialState: JournalState = {
  messages: [],
}

export const journalSlice = createSlice({
  name: 'journal',
  initialState,
  reducers: {
    log: (state, action: PayloadAction<string>) => {
      state.messages = [...state.messages, action.payload]
    },
  },
  extraReducers: builder => {


    builder
      .addCase(statsSlice.actions.reached, (state, action: PayloadAction<Arrival>) => {

        // const game = state.game.game
        // const locationPaper = game.location_papers[arrival.location_paper_id]
        // const location = game.locations[location_paper.location_id]
        const arrival = action.payload
        let message = `Tým ${arrival.teams.name} dorazil`
        if (arrival.time)
          message += ` v ${format(Date.parse(arrival.time), "HH.mm", { locale: cs })}`
//         if (location)
//           message += `na stanoviště ${location.name}`
        message += "."

        state.messages = [...state.messages, message]
      })
      .addCase(timerSlice.actions.reset, () => initialState)
  }
})

export const { log } = journalSlice.actions

export const selectMessages = (state: RootState) => state.journal?.messages

export default journalSlice.reducer
