import { createListenerMiddleware } from '@reduxjs/toolkit'
import * as d3 from "d3";
import { colorize } from '../../lib/colors';

import { reached } from '../stats/statsSlice'

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: reached,
  effect: async (action, listenerApi) => {
    const state = (listenerApi.getState() as any)
    const game = state.game.game
    const arrival = action.payload
    const team = game.teams[arrival.team_id]
    if (!arrival.next)
      return

    const svg = d3.select(`#location-paper-${arrival.location_paper_id}`)

    const count = Object.keys(state.location_paper_count.location_papers[arrival.location_paper_id]).length

    const start = Date.parse(arrival.time)
    const end = Date.parse(arrival.next.time)
    const duration = (end - start) / 128

    const g = svg.append("g")

    g
      .transition()
      .attr("opacity", 1)
      .duration(300)

    g.append("text")
      .attr("y", 30 * count)
      .attr("dy", "1.2em")
      .attr("font-size", '0.75em')
      .text(team.name);
    g
      .append("rect")
      .attr("x", 0)
      .attr("y", 30 * count)
      .attr("height", 3)
      .attr("fill", colorize(arrival.team_id))
      .transition()
      .attr("width", 300)
      .duration(duration)
      .ease(d3.easeLinear)
    g
      .transition()
      .attr("opacity", 0.1)
      .delay(duration)
      .duration(200)

  }
})

export default listenerMiddleware;
