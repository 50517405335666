import React from "react";

import { AuthenticationContext } from "../contexts/AuthenticationContext";
import { GameContext } from "../contexts/GameContext";
import { useErrorHandler } from "react-error-boundary";
import { Link, NavLink, Route, Routes } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Menu, Icon } from "semantic-ui-react";

import UserIcon from "./UserIcon";

import { q } from "../lib/Query";
import { api, handleResponse, initialState, receive } from "../lib/api";
import { useAppDispatch } from '../lib/hooks'
import { setGame as setGameState } from '../features/game/gameSlice'
import GamePanel from "./GamePanel";
import GameLogo from "./GameLogo";
import { indexBy } from "../lib/collection";
import { Game } from "../lib/interface";

function GameBoard() {
  const { gameName } = useParams();

  const [game, setGame] = React.useState(initialState());

  const [auth, setShowLoginForm] = React.useContext(AuthenticationContext);
  const dispatch = useAppDispatch()
  const handleError = useErrorHandler();

  React.useEffect(() => {
    if (!gameName) return;
    // if (auth.didInvalidate) return;
    // if (auth.isFetching) return;
    if (!game.didInvalidate) return;
    if (game.isFetching) return;
    api(auth?.token, "games")
      .from("games")
      .select(
        q([
          "game_id",
          "game_series_id",
          "title",
          "name",
          "start",
          "end",
          "volume",
          { "game_series": ["game_series_id", "name", "slug", { "...game_series_checksums": ["logo_checksum"] }] },
          { "...game_checksums": ["logo_checksum"] },
        ])
      )
      .eq("name", gameName)
      .single()
      .then(handleResponse(handleError, (game: Game) => {
        const queries = {
          puzzles: {
            puzzles: ["id:puzzle_id", "name", "position", "tag"],
            locations: ["id:location_id", "name", "lat:latitude", "lng:longitude"],
            location_papers: ["id:location_paper_id", "location_id", "paper_id"]
          },
          games: {
            teams: ["id:team_id", "name"],
          }
        }

        const tables = Object.values(queries).flatMap(items => Object.keys(items))
        const requests = Object.entries(queries).flatMap(([schema, items]) =>
          Object.entries(items)
            .map(([table, query]) =>
              api(auth?.token, schema)
                .from(table)
                .select(q(query))
                .eq("game_id", game.game_id))
        )
        Promise.all(requests).then(responses => {
          responses.forEach((response, i) => handleResponse(handleError, (values: []) => {
            game[tables[i]] = indexBy(values, ({ id }) => id)
          })(response))

          receive(game, setGame)
          dispatch(setGameState(game))
        })
      }), handleError)
  }, [gameName, auth, dispatch, game, handleError]);

  return (
    <>
      <Menu attached={"top"} inverted={true}>
        <Menu.Item as={NavLink} icon name="house" to="../">
          <Icon name="home" />
        </Menu.Item>
        {game.data && <Menu.Item>
          <GameLogo game={game.data} height={20}/>
          <Link to={""}>{game.data?.title}</Link>
        </Menu.Item>}
        <Menu.Item position="right" icon link onClick={() => setShowLoginForm(true)}>
          <UserIcon />
        </Menu.Item>
      </Menu>
      <GameContext.Provider value={game}>
        <Routes>
          <Route
            path="*"
            element={<GamePanel />}
          />
        </Routes>
      </GameContext.Provider>
    </>
  );
}

export default GameBoard;
